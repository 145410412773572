/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/lato-v23-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/lato-v23-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/lato-v23-latin-300.woff') format('woff'), /* Modern Browsers */
  url('fonts/lato-v23-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/lato-v23-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* oswald-300 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/oswald-v49-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/oswald-v49-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/oswald-v49-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/oswald-v49-latin-300.woff') format('woff'), /* Modern Browsers */
  url('fonts/oswald-v49-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/oswald-v49-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/oswald-v49-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/oswald-v49-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/oswald-v49-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/oswald-v49-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/oswald-v49-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/oswald-v49-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/oswald-v49-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/oswald-v49-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/oswald-v49-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/oswald-v49-latin-700.woff') format('woff'), /* Modern Browsers */
  url('fonts/oswald-v49-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/oswald-v49-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.oswald {
  font-family: 'Lato', 'sans-serif';
}

.prose h1, .prose h2, .prose h3, .prose h4 {
  font-family: 'Oswald', 'sans-serif';
  text-transform: uppercase;
  font-weight: normal;
}

.ff-errors {
  @apply text-red-500;
}

.hex {
  @apply bg-mhp h-20 w-20 py-5 px-3 mb-4 flex justify-center items-center self-center !important;
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.hex-big {
  @apply bg-white h-32 w-32 py-5 px-3 mb-4 flex justify-center items-center self-center !important;
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.hex-button {
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
  -webkit-clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
}

.hex-mentor {
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
}

form ul {
  @apply flex-col mb-2 lg:flex-row;
}

form ul li, form ul li a {
  @apply w-full block lg:w-auto lg:inline-block !important;
}

.bla {
  @apply text-white;
}

.blockFormular button {
  @apply bg-new-yellow mx-auto block lg:w-1/3 !important;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%) !important;
  -webkit-clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%) !important;
}

.ff-form-errors {
  @apply text-red-500 p-4 border-2 border-red-400 rounded mb-2 bg-red-100;
}
